import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import BodyCopy from '../components/atoms/body-copy'

const PrivacyPolicyPage = () => (
  <Layout hideSecureForm>
    <SEO title='Privacy Policy' />
    <Heading 
        title='Privacy Policy'
        weight='light'
    />
    <BodyCopy
      copy={
        [
          'Privacy',
          'Thank you for visiting our website demingsfloorrestore.com (referred to as the “Site”). Below is our privacy policy governing our use of information gathered about you (“Information”). Although we are committed to maintaining your confidence and trust in all of our activities with you, this Privacy Policy does not govern our collection of data through channels other than this Site. If you have any questions about this privacy statement or the content and practices of our web site, please feel free to contact us directly at: (954) 519-4658',
          'Information We Collect',
          'We collect Information when you register to access and use certain portions of the Site. We also collect Information from you that is necessary to identify you and to process and fulfill your requests for information or service. We also may set and access “cookies” on your computer. A cookie is a small piece of data, which often includes an anonymous unique identifier, that is set to your browser from a web site’s computers and is stored on your computer’s hard drive. We use these cookies to keep track of your movements on the Site so we can customize content for your review. Although you may configure your Internet web browser not to accept cookies, you will not be able to access all features of the Site if you disable our ability to use cookies on your browser.',
          'Our Use of Your Personal Information',
          'We use information collected from users in order to make the Site more useful and to respond to your queries, to inform users of new promotions, products and services offered by us, to improve our level of service, and to provide notifications regarding the Site and our products and services. We restrict access to your Information to those in our organization who require access in order to provide the Site, information and services to you. We also may share your Information with the following entities:',
          '– Our affiliated companies;',
          '– Unaffiliated service providers with whom we may subcontract, including web site hosting companies, fulfillment companies, payment processing companies, and other third party service providers; and',
          '– Government agencies and law enforcement officials, when required to do so to respond to subpoenas, court orders or legal process, or to establish or exercise or legal rights or defend against legal claims. We also reserve the right to share Information with other organizations in the following situations:',
          '– As permitted by law in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, violation of our intellectual property rights, situations involving potential threats to the physical safety of any person, violations of the Site’s Terms of Use or other agreements, or otherwise as required by law;',
          '– When we have your consent to do so; and',
          '– When we need to share Information to provide any product or service you have requested.',
          'We also may use your IP address and information about the web pages you visit to gather broad demographic information, interests and behavior so that we may better understand our Site users. This Information may be used, for example, to analyze and improve our Site design and functionality. We may share aggregated demographic, product usage, or traffic pattern information for marketing purposes with other organizations, but these statistics will not include information that identify you personally.',
          'Business Transfer',
          'As we continue to develop, we might sell or buy the assets of similar companies. In such transactions, customer and user Information generally is one of the transferred business assets. Also, in the event that we or substantially all of our assets are acquired, such Information may be one of those transferred assets, and we reserve the right to buy or sell such assets.',
          'Opt-out Policy',
          'When you provide Information via our web site, we may use the Information to occasionally notify you about special promotional programs and other valuable services we offer. If you prefer that we not contact you in this manner, we will promptly remove you from our mailing list at your request. Please send any such request to the address listed below.',
          'How to Review and Update Your Information',
          'You may write, e-mail or telephone us with changes to your information and contact preferences via the following numbers or addresses.',
          'Security',
          'We use a variety of standard protections to maintain the security of your online session. Although the Site has security measures in place to protect against the loss, misuse and alteration of Information under our control, we cannot guarantee that such security measures will be sufficient, will not be breached, and that your Information may not be disclosed or otherwise accessed in any unauthorized manner. Certain Information may be transmitted to you by electronic mail. Although it may be illegal to intercept or disclose these messages, these transmissions are not secured.',
          'Childern\'s Privacy Protection',
          'The Site does not target children or provide products or services for use by children. We do not knowingly collect Information from persons under 13 years of age. If you are under 13 years of age, you may review the Site only with involvement of a parent or guardian, and you may not use any portion of the Site that would require collection of personal information from you.',
          'Linked Internet Web sites',
          'The Site may provide hypertext links to other web sites that may contain privacy provisions that are different from those provided herein. We are not responsible for the collection, use or disclosure of information collected through those web sites, and we expressly disclaim any and all liability related to such collection, use or disclosure. You are encouraged to read the privacy policies of web sites reached through the use of links from the Site.',
          'Modification',
          'As we grow and new features are added, or legal requirements change, this privacy policy may be modified. Any changes to the Site’s privacy policy will be posted on the Site. All information submitted through the Site is subject to this privacy policy, as amended. This privacy policy was last updated in February, 2021.',
          'How to Contact Us',
          'If you have any questions or complaints about our Site, our Privacy Policy, or the Information we collect, please contact our customer service team at (954) 519-4658'
        ]
      }
    />
  </Layout>
)

export default PrivacyPolicyPage
